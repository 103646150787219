<!--
 * @Author: Dyf
 * @LastEditors: Please set LastEditors
 * @Date: 2023-03-22 18:14:02
 * @LastEditTime: 2023-11-20 16:42:47
 * @Descripttion: 个人中心
-->
<style lang="scss" scoped>
.personal-center {
  @include innerPage;
  @include flexBox(flex-start, flex-start);
  .page-tab {
    width: 140px;
    height: 100%;
    flex-shrink: 0;
    border-right: 1px solid rgba($color: #8b8b8b, $alpha: 0.12);
    box-sizing: border-box;
    @include flexBox;
    flex-direction: column;
    position: relative;

    .tab-item {
      width: 100%;
      text-align: center;
      font-size: 16px;
      line-height: 80px;
      color: #353535;
      cursor: pointer;
      &:hover {
        color: #6340c8;
      }

      &.current {
        color: #6340c8;

        &::after {
          transform: scaleY(1);
        }
      }
    }
    &::after {
      content: "";
      width: 5px;
      height: 40px;
      border-radius: 2.5px;
      background: #58419c;
      position: absolute;
      right: -2px;
      top: 20px;
      transform: translateY(var(--step));
      // transform: scaleY(0);
      @include defaultAni;
    }
  }
  .tab-container {
    box-sizing: border-box;
    padding: 32px;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    .person-info {
      height: 100%;
      @include flexBox(flex-start, flex-start);
      .calling-card {
        width: 100%;
        height: 391px;
        background: #4e329f;
        border-radius: 30px;
        margin-bottom: 36px;
        flex-direction: column;
        @include flexBox(center);

        &--wrapper {
          min-width: 200px;
          max-width: 330px;
          height: 464px;
          position: sticky;
          top: 0;
          left: 0;
          flex-grow: 1;
          flex-shrink: 0;
          overflow: hidden;

          .btn-group {
            width: 100%;
            @include flexBox(center);
          }
        }

        .avatar-uploader {
          width: 145px;
          height: 145px;
          border-radius: 50%;
          border: 2px solid #e8eaf3;
          position: relative;

          .camera {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 2px solid #ededed;
            background: #fff;
            position: absolute;
            bottom: 0;
            right: 8px;
            text-align: center;
            line-height: 32px;
            color: #cbcbcb;
            font-size: 18px;
          }

          .avatar,
          .el-upload {
            width: 100%;
            height: 100%;
          }

          .avatar {
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .phone {
          font-size: 18px;
          color: #e8eaf3;
          margin-top: 18px;
        }
      }

      .info-wrapper {
        width: 752px;
        height: 100%;
        max-height: 668px;
        min-width: 400px;
        border-radius: 30px;
        background: #fff;
        margin-right: 30px;
        box-sizing: border-box;
        padding: 24px 68px;
        overflow: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar{
          display: none;
        }

        .info-item {
          height: 126px;
          border-bottom: 1px solid #e8eaf3;
          flex-direction: column;
          @include flexBox(center, flex-start);

          &:last-child {
            border-bottom: none;
          }

          .label {
            color: #262626;
            line-height: 24px;
          }

          .val {
            width: 100%;
            line-height: 24px;
            margin-top: 10px;

            span {
              font-size: 16px;
              color: #262626;
            }

            @include flexBox(space-between);
          }
        }
      }
    }
    .parents {
      width: 100%;
      height: 100%;
      position: relative;
      ul {
        max-height: 100%;
        overflow: hidden;
        overflow-y: auto;
        background: #f0f3fa;
        border-radius: 10px;
        @include flexBox(space-between);
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 20px;
      }
      .parent {
        width: 100%;
        max-width: 650px;
        height: 137px;
        border-bottom: 1px solid #e2e5eb;
        @include flexBox;
        .el-avatar {
          margin-right: 10px;
        }
        .name-edit {
          height: 46px;
          @include flexBox;

          input {
            max-width: 200px;
            min-width: 24px;
            height: 46px;
            line-height: 46px;
            border: none;
            background: transparent;
            color: #333;
            text-align: left;
            font-size: 18px;
            font-weight: bold;
            outline: none;
          }

          .el-button {
            padding-left: 0;
            margin-right: -74px;
          }
        }
        .btns {
          margin-left: auto;
          margin-top: 28px;
        }
      }
    }
    .device {
      width: 100%;
      height: 100%;
      .search-form {
        @include flexBox;
      }
      .device-box {
        margin-top: 32px;
        height: calc(100% - 78px);
        position: relative;
      }
      .device-list {
        max-height: 100%;
        overflow: hidden;
        overflow-y: auto;
        background: #f0f3fa;
        border-radius: 10px;
        @include flexBox(space-between);
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 0 20px 20px;
        li {
          width: 100%;
          height: 137px;
          border-bottom: 1px solid #e2e5eb;
          position: relative;
          @include flexBox;
          .device-thumb {
            width: 100px;
            height: 100px;
            margin-right: 18px;
            border-radius: 4px;
            overflow: hidden;
            .el-image {
              width: 100%;
              height: 100%;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .name-edit {
            height: 46px;
            @include flexBox;

            input {
              min-width: 24px;
              height: 46px;
              line-height: 46px;
              border: none;
              background: transparent;
              color: #333;
              text-align: left;
              font-size: 18px;
              font-weight: bold;
              outline: none;
            }

            .el-button {
              padding-left: 0;
              margin-right: -74px;
            }
          }
          .desc {
            color: #6b6b6b;
            line-height: 24px;
          }
          .tips {
            color: #1fccb6;
            line-height: 24px;
            margin-top: 8px;
          }
          .btns {
            margin-left: auto;
            margin-top: 28px;
            z-index: 1;
          }
          &.expire::before {
            content: "";
            font-family: "iconfont";
            font-size: 60px;
            color: #8b8b8b;
            position: absolute;
            z-index: 0;
            right: 0px;
            top: 6px;
          }
        }
      }
    }
  }
}
.dialog-container {
  box-sizing: border-box;
  padding: 34px 0 48px;

  .el-form-item {
    width: 100%;
  }

  .el-form-item:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1200px) {
}
</style>
<style lang="scss">
.el-popconfirm__main {
  margin-top: 8px;
}
.el-popconfirm__action {
  margin-top: 14px;
}
</style>

<template>
  <section class="personal-center">
    <ul class="page-tab bold" :style="{ '--step': tabStep }">
      <li
        class="tab-item"
        :class="{ current: info_type == 1 }"
        @click="changeTab(1)"
      >
        个人资料
      </li>
      <li
        class="tab-item"
        :class="{ current: info_type == 2 }"
        @click="changeTab(2)"
      >
        家长信息
      </li>
      <li
        class="tab-item"
        :class="{ current: info_type == 3 }"
        @click="changeTab(3)"
      >
        我的设备
      </li>
    </ul>
    <div class="tab-container">
      <div class="person-info" v-show="info_type == 1">
        <ul class="info-wrapper">
          <li class="info-item">
            <h5 class="label bold">姓名</h5>
            <p class="val">
              <span class="pf">{{ userData.stuser_name || "-" }}</span>
            </p>
          </li>
          <li class="info-item">
            <h5 class="label bold">性别</h5>
            <p class="val">
              <span class="pf">{{
                (userData.stuser_sex == 10 && "男") ||
                (userData.stuser_sex == 20 && "女") ||
                "-"
              }}</span>
            </p>
          </li>
          <li class="info-item">
            <h5 class="label bold">学校</h5>
            <p class="val">
              <span class="pf">{{ userData.scsch_name || "-" }}</span>
            </p>
          </li>
          <li class="info-item">
            <h5 class="label bold">班级</h5>
            <p class="val">
              <span class="pf">{{ userData.grade || "-" }}</span>
            </p>
          </li>
        </ul>
        <div class="calling-card--wrapper">
          <div class="calling-card">
            <el-upload
              class="avatar-uploader"
              :action="$upload.imgAction"
              :headers="$upload.header"
              :show-file-list="false"
              :accept="$upload.imgAccept"
              :before-upload="$beforImgUpload"
              :on-success="handleAvatarSuccess"
            >
              <el-avatar
                fit="cover"
                :size="145"
                :src="formatfile(userData.stuser_image)"
                class="avatar"
              >
                <img src="@assets/images/empty_avatar.png" />
              </el-avatar>
              <p class="camera iconfont">&#xe621;</p>
            </el-upload>
            <p class="phone pf">学号：{{ userData.stuser_stuno }}</p>
          </div>
          <div class="btn-group">
            <el-button
              type="custom_primary"
              size="small"
              @click="passwordDialog = true"
              >修改密码</el-button
            >
          </div>
        </div>
      </div>
      <div class="parents" v-show="info_type == 2" v-loading="parents_load">
        <ul v-show="!$isEmpty(parents_list)">
          <li class="parent" v-for="(item, index) in parents_list" :key="index">
            <el-avatar
              fit="cover"
              :size="62"
              :src="formatfile(item.pauser_image)"
            >
              <img src="@assets/images/empty_avatar.png" />
            </el-avatar>
            <div>
              <div class="name-edit">
                <input
                  :style="{
                    width: `${
                      (item.pauser_stuser_remarks &&
                        item.pauser_stuser_remarks.length > 5 &&
                        120) ||
                      (item.pauser_stuser_remarks &&
                        item.pauser_stuser_remarks.length * 24) ||
                      24
                    }px`,
                  }"
                  :ref="`remark_ipt_${index}`"
                  v-model="item.pauser_stuser_remarks"
                  type="text"
                  maxlength="20"
                  :readonly="!item.remark_edit"
                  @blur="
                    item.remark_edit = false;
                    setParentRemark(index);
                  "
                  @input="
                    item.pauser_stuser_remarks =
                      item.pauser_stuser_remarks.replace(/\s+/g, '')
                  "
                />
                <el-button
                  type="text"
                  class="success"
                  @mousedown.native="remarkFocus($event, index)"
                >
                  <i class="iconfont">&#xe61e;</i>修改
                </el-button>
              </div>
              <p class="phone">
                {{ item.pauser_phone | phoneEncrypt }}
              </p>
            </div>
            <div class="btns">
              <template v-if="item.pauser_stuser_status == 10">
                <el-button
                  type="custom_warning"
                  size="mini"
                  @click="bindingParent(item.pauser_stu_id, 25)"
                  >拒绝</el-button
                >
                <el-button
                  type="custom_success"
                  size="mini"
                  @click="bindingParent(item.pauser_stu_id, 20)"
                  >绑定</el-button
                >
              </template>
              <el-button
                v-if="item.pauser_stuser_status != 10"
                type="custom_info"
                size="mini"
                >已绑定</el-button
              >
            </div>
          </li>
        </ul>
        <div class="no-data--empty" v-show="$isEmpty(parents_list)">
          <img src="@assets/images/no-data3.png" alt="" />
          <p>暂无绑定家长哦~</p>
        </div>
      </div>
      <div class="device" v-show="info_type == 3">
        <div class="search-form">
          <el-form inline>
            <el-form-item>
              <el-input
                v-model.trim="stboa_title"
                maxlength="100"
                placeholder="输入手写板名称"
                clearable
              >
                <span slot="prefix" class="iconfont">&#xe61c;</span>
              </el-input>
            </el-form-item>
          </el-form>
          <el-button
            type="custom_primary"
            size="medium"
            @click="
              devices_load = true;
              getDevices();
            "
            >查询</el-button
          >
          <el-button
            type="custom_success"
            size="medium"
            @click="deviceDialog = true"
            >绑定</el-button
          >
        </div>
        <div class="device-box" v-loading="devices_load">
          <ul class="device-list" v-show="!$isEmpty(devices_list)">
            <li
              v-for="(item, index) in devices_list"
              :key="index"
              :class="{ expire: item.isexpire == 1 }"
            >
              <p class="device-thumb">
                <el-image fit="cover" :src="formatfile(item.stboa_image)">
                  <img slot="error" src="@assets/images/empty_device.jpg" />
                </el-image>
              </p>
              <div>
                <div class="name-edit">
                  <input
                    :style="{
                      width: `${
                        (item.stuser_boardtitle &&
                          item.stuser_boardtitle.length < 5 &&
                          120) ||
                        (item.stuser_boardtitle &&
                          item.stuser_boardtitle.length * 24) ||
                        24
                      }px`,
                    }"
                    :ref="`boardtitle_ipt_${index}`"
                    v-model="item.stuser_boardtitle"
                    type="text"
                    maxlength="20"
                    :readonly="!item.boardtitle_edit"
                    @blur="
                      item.boardtitle_edit = false;
                      setBoardtitle(index);
                    "
                    @input="
                      item.stuser_boardtitle = item.stuser_boardtitle.replace(
                        /\s+/g,
                        ''
                      )
                    "
                  />
                  <el-button
                    type="text"
                    class="success"
                    @mousedown.native="boardtitleFocus($event, index)"
                  >
                    <i class="iconfont">&#xe61e;</i>修改
                  </el-button>
                </div>
                <!-- <p class="desc">
                  <span v-html="item.stboa_remark"></span>
                </p> -->
                <p class="tips">
                  使用时长：{{ getDay(item.stboa_interlinkagetime) }}天
                </p>
              </div>
              <div class="btns">
                <el-popconfirm
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  icon="el-icon-info"
                  icon-color="red"
                  title="确定确认解除绑定该设备？"
                  @confirm="secureDeviceBind(item.stboa_id)"
                >
                  <el-button slot="reference" type="custom_warning" size="mini"
                    >解除绑定</el-button
                  >
                </el-popconfirm>
              </div>
            </li>
          </ul>
          <div class="no-data--empty" v-show="$isEmpty(devices_list)">
            <img src="@assets/images/no-data3.png" alt="" />
            <p>暂未绑定设备哦~</p>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="修改密码"
      width="450px"
      :visible.sync="passwordDialog"
      :show-close="false"
      :close-on-click-modal="false"
      @close="hideDialog('password')"
    >
      <div class="dialog-container">
        <el-form
          ref="passwordForm"
          :model="passwordForm"
          :rules="passwordRules"
        >
          <el-form-item prop="oldPassword">
            <el-input
              v-model.trim="passwordForm.oldPassword"
              type="password"
              maxlength="16"
              placeholder="请输入旧密码"
            />
          </el-form-item>
          <el-form-item prop="newPassword">
            <el-input
              v-model.trim="passwordForm.newPassword"
              type="password"
              maxlength="16"
              placeholder="请设置新密码(8~16位)"
            />
          </el-form-item>
          <el-form-item prop="againPassword">
            <el-input
              v-model.trim="passwordForm.againPassword"
              type="password"
              maxlength="16"
              placeholder="请再次确认新密码(8~16位)"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button
          type="custom_info"
          size="small"
          @click="hideDialog('password')"
          >取 消</el-button
        >
        <el-button
          type="custom_primary"
          size="small"
          @click="setPwd"
          :disabled="btnload"
          v-loading="btnload"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="绑定设备"
      width="450px"
      :visible.sync="deviceDialog"
      :show-close="false"
      :close-on-click-modal="false"
      @close="hideDialog('device')"
    >
      <div class="dialog-container">
        <el-form ref="deviceForm" :model="deviceForm" :rules="deviceRules">
          <el-form-item prop="stboa_mac">
            <el-input
              v-model.trim="deviceForm.stboa_mac"
              type="text"
              maxlength="11"
              placeholder="请输入设备码"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="custom_info" size="small" @click="hideDialog('device')"
          >取 消</el-button
        >
        <el-button
          type="custom_primary"
          size="small"
          @click="bindDevice"
          :disabled="btnload"
          v-loading="btnload"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </section>
</template>

<script>
import * as $a_c from "@api/center";
import { mapActions, mapState } from "vuex";
import { formatFile, iptInteger } from "@utils";
import { validPassword } from "@utils/validate";
import Base64 from "base-64";
export default {
  name: "home_personalCenter",
  computed: {
    ...mapState("user", ["userInfo"]),
    tabStep() {
      let init_step = -80 + this.info_type * 80;
      return `${init_step}px`;
    },
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
    iptInit() {
      return function (val) {
        return iptInteger(val, "");
      };
    },
    getDay() {
      return (beginDate) => {
        return Math.floor(
          Math.abs(new Date().valueOf() / 1000 - beginDate) / (3600 * 24) + 1
        );
      };
    },
  },
  watch: {
    userInfo: {
      handler(newVal) {
        this.userData = { ...newVal };
      },
      immediate: true,
    },
  },
  data() {
    // 密码校验
    let validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (value.length < 8 || value.legnth > 16) {
        callback(new Error("请输入8~16位密码"));
      } else if (!validPassword(value)) {
        callback(new Error("密码包含数字、大写字母、小写字母、特殊字符"));
      } else {
        callback();
      }
    };
    // 再次输入密码校验
    let validatePass2 = (rule, value, callback) => {
      if (!value && this.passwordForm.newPassword) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passwordForm.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      /* 个人信息  */
      info_type: 1, // 1 = 个人资料；2 = 家长信息；3 = 我的设备
      userData: {},
      btnload: false,
      passwordDialog: false, // 显示修改密码弹窗
      passwordForm: {}, // 修改密码表单
      /* 密码表单校验规则 */
      passwordRules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        againPassword: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
      /* 家长信息 */
      parents_load: false,
      parents_data: [],
      parents_list: [],
      /* 我的设备 */
      stboa_title: "", // 手写板名称筛选
      devices_load: false,
      devices_data: [],
      devices_list: [],
      deviceDialog: false, // 绑定设备弹窗
      deviceForm: {}, // 绑定设备表单
      deviceRules: {
        stboa_mac: [
          { required: true, message: "请输入设备码", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    /** 切换信息展示 */
    changeTab(val) {
      this.info_type = val;
      switch (val) {
        case 2:
          this.parents_data = [];
          this.parents_list = [];
          this.parents_load = true;
          this.getParents();
          break;
        case 3:
          this.stboa_title = "";
          this.devices_data = [];
          this.devices_list = [];
          this.devices_load = true;
          this.getDevices();
          break;
        default:
          break;
      }
    },
    /** 修改密码 */
    async setPwd() {
      this.$refs.passwordForm.validate(async (valid) => {
        if (valid) {
          this.btnload = true;
          let params = {
            stuser_password: Base64.encode(this.passwordForm.oldPassword),
            new_pwd: Base64.encode(this.passwordForm.newPassword),
            again_pwd: Base64.encode(this.passwordForm.againPassword),
          };
          let res = await $a_c.update_pwd(params);
          this.btnload = false;
          // 密码修改成功后跳转重新登录
          if (res) {
            this.hideDialog("password");
            this.$message.success("密码修改成功,即将跳转重新登录");
            //删除强制修改密码
            sessionStorage.removeItem("updatePwd");
            setTimeout(() => {
              sessionStorage.clear();
              this.$router.replace({ name: "LOGIN" });
            }, 300);
          }
        }
      });
    },
    /** 修改学生信息 */
    async setUserInfo() {
      let { userData } = this;
      await $a_c.update_image({ stuser_image: userData.stuser_image });
      this.getUserInfo();
    },
    /**
     * 文件上传成功
     * @param {*} res 响应结果
     * @param {file} file 返回文件
     */
    handleAvatarSuccess({ data, msg }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      this.userData.stuser_image = data.address;
      /* 操作修改用户信息 */
      this.setUserInfo();
    },
    /** 关闭弹窗 */
    hideDialog(ref) {
      console.log(ref);
      this[`${ref}Dialog`] = false;
      this.resetForm(ref);
      this[`${ref}Form`] = {};
    },
    /** 重置表单 */
    resetForm(ref) {
      this.$refs[`${ref}Form`].resetFields();
    },
    /** 获取家长列表 */
    async getParents() {
      let { data: res } = await $a_c.paruserstuserList({ pagesize: 100 });
      this.parents_load = false;
      this.parents_data = JSON.parse(JSON.stringify(res.data));
      res.data.forEach((item) => (item.remark_edit = false));
      this.parents_list = res.data;
    },
    /** 获取设备列表 */
    async getDevices() {
      let { data: res } = await $a_c.stuboardList({
        pagesize: 100,
        stboa_title: this.stboa_title,
      });
      this.devices_load = false;
      this.devices_data = JSON.parse(JSON.stringify(res.data));
      res.data.forEach((item) => (item.boardtitle_edit = false));
      this.devices_list = res.data;
    },
    /** 修改家长备注 */
    async setParentRemark(index) {
      let parents = [...this.parents_list];
      let item = parents[index];
      let old_item = this.parents_data[index];
      if (!item.pauser_stuser_remarks) {
        item.pauser_stuser_remarks = old_item.pauser_stuser_remarks;
        parents[index] = { ...item };
        this.parents_list = [...parents];
        this.$forceUpdate();
        return;
      } else {
        let params = {
          pauser_stu_id: item.pauser_stu_id,
          pauser_stuser_remarks: item.pauser_stuser_remarks,
        };
        let { status } = await $a_c.paruserstuserEdit_remarks(params);
        this.getParents();
        // if (status == 200) return this.$message.success("修改成功");
      }
    },
    /** 绑定/拒绝绑定家长 */
    async bindingParent(pauser_stu_id, pauser_stuser_status) {
      let params = { pauser_stu_id, pauser_stuser_status };
      let { status } = await $a_c.paruserstuserAgree_refuse(params);
      if (status == 200) {
        this.$message.success("操作成功");
        this.getParents();
      }
    },
    /** 家长备注输入框聚焦 */
    remarkFocus(e, index) {
      this.parents_list[index].remark_edit = true;
      this.$refs[`remark_ipt_${index}`][0].focus();
      this.$nextTick(() => {
        this.$refs[`remark_ipt_${index}`][0].setSelectionRange(
          this.parents_list[index].pauser_stuser_remarks.length,
          this.parents_list[index].pauser_stuser_remarks.length
        );
      });
      e.preventDefault();
    },
    /** 修改设备备注 */
    async setBoardtitle(index) {
      let devices = [...this.devices_list];
      let item = devices[index];
      let old_item = this.devices_data[index];
      if (!item.stuser_boardtitle) {
        item.stuser_boardtitle = old_item.stuser_boardtitle;
        devices[index] = { ...item };
        this.devices_list = [...parents];
        this.$forceUpdate();
        return;
      } else {
        let params = {
          stboa_id: item.stboa_id,
          stuser_boardtitle: item.stuser_boardtitle,
        };
        let { status } = await $a_c.update_board_name(params);
        this.getDevices();
        // if (status == 200) return this.$message.success("修改成功");
      }
    },
    /** 设备备注输入框聚焦  */
    boardtitleFocus(e, index) {
      this.devices_list[index].boardtitle_edit = true;
      this.$refs[`boardtitle_ipt_${index}`][0].focus();
      this.$nextTick(() => {
        this.$refs[`boardtitle_ipt_${index}`][0].setSelectionRange(
          this.devices_list[index].stuser_boardtitle.length,
          this.devices_list[index].stuser_boardtitle.length
        );
      });
      e.preventDefault();
    },
    /** 绑定设备 */
    bindDevice() {
      this.$refs.deviceForm.validate(async (valid) => {
        if (valid) {
          this.btnload = true;
          let params = {
            stboa_code: "",
            stboa_mac: this.deviceForm.stboa_mac,
          };
          // 4A-AA-D9-34
          let res = await $a_c.stuboardBinding(params);
          this.btnload = false;
          // 密码修改成功后跳转重新登录
          if (res) {
            this.getDevices();
            this.hideDialog("device");
            this.$message.success("设备绑定成功");
          }
        }
      });
    },
    /** 解除设备绑定 */
    async secureDeviceBind(stboa_id) {
      let { status } = await $a_c.stuboardRemove_binding({ stboa_id });
      if (status == 200) {
        this.getDevices();
        this.$message.success("设备解绑成功");
      }
    },
  },
};
</script>